<template>
    <div class="w-full block" :style="'margin-bottom: ' + bottom + 'px;'">
        <div class="bg-gray-200 py-5" v-if="data">
            <div class="container flex w-full">
                <div class="block" v-for="(item, n) in data.Data" :key="n">
                    <div v-if="item.Type === 'about'" class="block" style="max-width: 500px;">
                        <div class="flex mb-2 cursor-pointer" @click="$router.push('home')">
                            <img :src="item.Logo" width="125px" alt="">
                        </div>
                        <small>{{ item.Description }}</small>
                        <div class="flex mt-2">
                            <div class="mr-2 cursor-pointer" v-for="(social, indexSocial) in item.Social" :key="indexSocial">
                                <b-icon
                                    :icon="social.icon"
                                    size="is-small">
                                </b-icon>
                            </div>
                        </div>
                    </div>
                    <div class="mx-5 block" v-if="item.Type === 'menu'">
                        <h5 class="text-lg font-semibold mb-2">{{ item.Title }}</h5>
                        <ul>
                            <li @click="$router.push(menu.Url)" class="cursor-pointer mb-1" v-for="(menu, indexMenu) in item.Data" :key="indexMenu">
                                <small class="text-blue-300">{{ menu.Title }}</small>
                            </li>
                        </ul>
                    </div>
                    <div class="mx-5" v-if="item.Type === 'contactinfo'">
                        <h5 class="text-lg font-semibold mb-2">{{ item.Title }}</h5>
                        <div class="block" v-if="item.Data">
                            <div class="flex mb-2" v-if="item.Data.Address">
                                <b-icon class="my-auto" icon="map-marker" size="is-small"></b-icon>
                                <small class="ml-2">{{ item.Data.Address }}</small>
                            </div>
                            <div class="flex mb-2" v-if="item.Data.Email">
                                <b-icon class="my-auto" icon="email" size="is-small"></b-icon>
                                <small class="ml-2">{{ item.Data.Email }}</small>
                            </div>
                            <div class="flex" v-if="item.Data.Phone">
                                <b-icon class="my-auto" icon="phone" size="is-small"></b-icon>
                                <small class="ml-2">{{ item.Data.Phone }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-700 py-2" v-if="data && data.Copyright">
            <div class="container text-white flex w-full justify-between">
                <div class="flex">
                    <small>{{ data.Copyright }}</small>
                </div>
                <div class="flex social">
                    <div class="mr-2" v-for="(image, indexImage) in data.Images" :key="indexImage">
                        <img :src="image" style="width: 20px;" class="w-full h-full object-cover" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        data () {
            var company = this.$store.state.service.company
            return company.Footer
        },
        bottom () {
            return this.$store.state.service.bottomHeight
        }
    }
}
</script>
